'use client';

import {useRouter} from 'next-nprogress-bar';
import {useEffect} from 'react';

export default function Page() {
  const router = useRouter();

  useEffect(() => {
    router.replace('/auth/login');
  }, []);

  return <></>;
}
